import React from 'react'
import styled from 'styled-components'
import { compose, pick, map } from 'ramda'
import Grid from '@material-ui/core/Grid'
import Link from 'components/Link'
import StoryblokComponent from 'components/StoryblokComponent'
import { Container } from 'components/RemarkRenderer'
import { color } from 'utils/style'

function parsePrimitive(v) {
  try {
    return JSON.parse(v)
  } catch (_) {
    return v
  }
}

const StyledGrid = styled(Grid)`
  background-color: ${color('bg', 'color')};
  color: ${color('bg', 'contrast')};
  && {
    ${({ margin }) => (margin ? 'border: 8px solid white' : '')};
  }
  ${Container} {
    a {
      color: ${color('bg', 'highlight')};
    }
  }
  h1,
  h2,
  h3 {
    color: ${color('bg', 'highlight')};
  }
  h4,
  h5,
  h6 {
    color: ${color('bg', 'contrast')};
  }
  button {
    background-color: ${color('bg', 'button')};
  }
  .title,
  .roofline,
  .subline {
    color: ${color('bg', 'contrast')};
  }
`

export default function StoryblokGrid({ data }) {
  const {
    alignContent,
    alignItems,
    direction,
    justify,
    spacing,
    wrap,
    container,
    item,
    body,
    sizes,
    color,
    margin,
    link,
    enableLink,
  } = data

  const cleanSizes = compose(
    map(v => (v === '' ? false : v)),
    map(parsePrimitive),
    pick(['xs', 'sm', 'md', 'lg', 'xl'])
  )(sizes || data)

  const containerProps = {
    alignContent,
    alignItems,
    direction,
    justify,
    spacing: 1,
    // spacing: spacing == null ? 1 : spacing,
    // spacing: Number(spacing),
    wrap,
  }

  const itemProps = {
    ...cleanSizes,
  }

  const BodyContainer = enableLink ? Link : React.Fragment

  return (
    <StyledGrid
      bg={color}
      container={container}
      item={item}
      margin={margin}
      {...container && containerProps}
      {...item && itemProps}
    >
      <BodyContainer {...enableLink && { link }}>
        {body?.length ? (
          body.map(b => <StoryblokComponent key={b.id} component={b} />)
        ) : (
          <div />
        )}
      </BodyContainer>
    </StyledGrid>
  )
}
